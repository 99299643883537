/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

	

	var Sage = {

		'common': {
			init: function() {
		
			},
			finalize: function() {

				function change_slide(e){
					var $this = e;
					var slide = $this.find('li.active').index()+1;
					var nb = $this.find('li').length ; // 2

					console.log(slide +' ' +nb);
					if(slide >= $this.find('li').length ){ slide = 0; }
					//alert(slide);
					$this.find('li.active').removeClass('active');
					$this.find('li').eq(slide).addClass('active');
					setTimeout(function(){
							change_slide($this);  
						}, 4000);
				}

				$("#background").find('.col-background').each(function(){
					var base = $(this);
					
					if($(this).find('li').length > 1){

						setTimeout(function(){
							change_slide(base);  
						}, 4000 );
					
					}
				});
			
			}
		}
	};






	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
